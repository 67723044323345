/* eslint-disable func-names, no-var, wrap-iife */
import { ThirdPartyService, thirdPartyManager, userManager } from 'sat-core';

/**
 * @see https://docs.userback.io/docs/widget-installation-methods
 */
ThirdPartyService.initAdditionalThirdPartyChatLauncherLogic = () => {
  const OUR_USERBACK_ACCESS_TOKEN = 'P-Wp8GlZkPdIqXTeG3mAzhC7geJ';

  const ALLOWED_TIMES_TRIED = 10;
  let timesTried = 0;
  const interval = setInterval(() => {
    if (window.Userback) {
      const { user } = userManager;

      window.Userback.init(OUR_USERBACK_ACCESS_TOKEN);
      window.Userback.identify(user.userId, {
        account_id: user.userId,
        email: user.username,
        name: user.displayName
      });

      clearInterval(interval);
    } else {
      timesTried++;
      if (timesTried >= ALLOWED_TIMES_TRIED) {
        // give up
        clearInterval(interval);
      }
    }
  }, 500);
};

ThirdPartyService.removeThirdPartyChatLauncherIfApplicable = ({
  alsoRegenerateChatLauncherId = false
} = {}) => {
  if (window.Userback) {
    window.Userback.destroy();
    if (alsoRegenerateChatLauncherId) {
      thirdPartyManager.regenerateThirdPartyChatLauncherId();
    }
  }
};
