import React from 'react';
import { ProgressView, SatCoreRegister, StudentHelperService } from 'sat-core';

import iconCommentActiveYellow from '../img/icon-comment-active-yellow.svg';

// const ProgressViewSummaryLegend = ({ data }) => {
//   return StudentHelperService.getProgressSummaryLegend({ data });
// };

const ProgressViewOverride = (props) => {
  const progressViewOverrideOptions = StudentHelperService.getProgressViewOverrideOptions({
    progressWidget: false,
    summaryWidget: false,
    trophiesWidget: false,
    weeklyPerformanceWidget: false
  });
  return (
    <ProgressView {...props} {...progressViewOverrideOptions}
      // summaryLegend={ProgressViewSummaryLegend}
      feedbackIconActiveImg={iconCommentActiveYellow}
      leftCol={true} />
  );
};

SatCoreRegister('ProgressView', ProgressViewOverride);
