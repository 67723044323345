const translations = {
  AddAssignmentModal: {
    standardsApproachingLabel: 'Developing',
    standardsDevelopingLabel: 'Emerging',
    standardsMasteringLabel: 'Mastering',
    standardsMeetingLabel: 'Proficient',
    standardsNotEnoughDataLabel: 'Not Enough Data'
  },
  AssignmentMenu: {
    CurrentAssignments: 'Current'
  },
  ColorKeyCard: {
    courseApproachingLabel: 'Developing',
    courseApproachingSubLabel: '60% - 79%',
    courseDevelopingLabel: 'Emerging',
    courseDevelopingSubLabel: '< 60%',
    courseMasteringLabel: 'Mastering',
    courseMasteringSubLabel: 'N/A',
    courseMeetingLabel: 'Proficient',
    courseMeetingSubLabel: '≥ 80%',
    courseNotEnoughDataLabel: 'Not Enough Data',
    courseNotEnoughDataSubLabel: '',
    standardsApproachingLabel: 'Developing',
    standardsApproachingSubLabel: '60% - 79%',
    standardsDevelopingLabel: 'Emerging',
    standardsDevelopingSubLabel: '< 60%',
    standardsMasteringLabel: 'Mastering',
    standardsMasteringSubLabel: 'N/A',
    standardsMeetingLabel: 'Proficient',
    standardsMeetingSubLabel: '≥ 80%',
    standardsNotEnoughDataLabel: 'Not Enough Data',
    standardsNotEnoughDataSubLabel: ''
  },
  Login: {
    buttonSignUpLabel: 'Sign Up',
    forgotPassword: 'Forgot password'
  },
  ProgressView: {
    score: 'Progress',
  },
  ReportScoreSummaryBarCell: {
    courseApproaching: 'Developing',
    courseDeveloping: 'Emerging',
    courseMastering: 'Mastering',
    courseMeeting: 'Proficient',
    courseNotEnoughData: 'Not Enough Data',
    standardsApproaching: 'Developing',
    standardsDeveloping: 'Emerging',
    standardsMastering: 'Mastering',
    standardsMeeting: 'Proficient',
    standardsNotEnoughData: 'Not Enough Data'
  },
  StudentDashboard: {
    BookLabel: 'My Content',
    WelcomeLabel: 'Welcome,'
  },
  StudentTopNav: {
    BookLabel: 'My Content'
  },
  TeacherDashboard: {
    WelcomeLabel: 'Welcome,'
  }
};

export default translations;
