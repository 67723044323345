import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'mobx-react';

import {
  Auth, SatCoreComponent, accommodationsManager, assignmentManager,
  classroomManager, contentManager, courseManager, dialogManager,
  gradebookManager, i18nAddSatellite, navigationManager,
  studentProgressManager, thirdPartyManager, userManager
} from 'sat-core';

import { API_ROOT, LESSON_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';

// import iconCommentActiveYellow from './img/icon-comment-active-yellow.svg';
import logo from './img/openupresources-logo-sm-dark.svg';

import 'semantic-ui-less/semantic.less';
import './index.less';
import * as serviceWorker from './serviceWorker';

/* Put component override imports here */
import './views/MainViewOverride';
import './views/LoginOverride';
import './views/ProgressViewOverride';
import './components/CourseListBannerOverride';
import './components/StudentAssignmentCardOverride';
import './services/AccommodationsServiceOverride';
import './services/ThirdPartyServiceOverride';

import openupresourcesTranslations from './openupresourcesTranslations';

i18nAddSatellite('openupresources', openupresourcesTranslations);

const App = SatCoreComponent('App');
Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo, LESSON_ROOT, {});

// assignmentManager.setCustomDefaultAssignmentCardImg(customDefaultAssignmentCardImg);
// contentManager.setFeedbackIconActiveImg(iconCommentActiveYellow);
accommodationsManager.setIncludeAssignmentAccommodations(false);
accommodationsManager.setIncludeClassroomAccommodations(true);
assignmentManager.setAlwaysExcludeStudentAssignmentCardAlignmentPills(true);
assignmentManager.setEnableCourseSearch(true);
assignmentManager.setIncludeAlignments(true);
assignmentManager.setLateSubmissionOptionAllowed(true);
classroomManager.setEnabledStudentsOnly(false);
classroomManager.setIncludeGroups(true);
contentManager.setResourceIFrameUrl('mailto:ICsupport@openup.org');
contentManager.setResourceIFrameUrlStudent('mailto:ICsupport@openup.org');
courseManager.setAllowStudentBooks(true);
courseManager.setIncludeAlignments(true);
dialogManager.setUseWhiteInfoIcon(true);
gradebookManager.setAllowAssignmentLink(true);
gradebookManager.setAllowObservationalGrader(true);
navigationManager.setAllowCarouselAdaptiveHeight(true);
studentProgressManager.setAllowStudentProgressNavTab(true);
thirdPartyManager.setAllowedThirdPartyChatLauncherUserRoles(['district_admin_user', 'school_admin_user', 'teacher_user']);
thirdPartyManager.setThirdPartyChatLauncherUrl('https://static.userback.io/widget/v1.js');
userManager.setAllowStudentUsernamesAsEmail(true);
userManager.setClientPrefix('OUR');

ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    userManager={userManager}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
